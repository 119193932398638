const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');
const Image = require('nordic/image');

const ICON_ID = 'rep_seller_attention_good_v2';
const namespace = 'ui-pdp-icon ui-pdp-icon--message-positive';

const IconMessagePositiveV2 = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="message-positive-v2.svg" alt="" />
  </div>
);

IconMessagePositiveV2.propTypes = {
  className: string,
};

IconMessagePositiveV2.defaultProps = {
  className: null,
};

module.exports = React.memo(IconMessagePositiveV2);
exports = module.exports;
exports.ICON_ID = ICON_ID;
